import React from 'react'

const NextButtonIcon = () => {
  return (
    <svg
      width="179"
      height="179"
      viewBox="0 0 179 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="89.5" cy="89.5" r="89.5" fill="#E4002B" />
      <path
        d="M137.414 86.4142C138.195 85.6332 138.195 84.3668 137.414 83.5858L124.686 70.8579C123.905 70.0768 122.639 70.0768 121.858 70.8579C121.077 71.6389 121.077 72.9052 121.858 73.6863L133.172 85L121.858 96.3137C121.077 97.0948 121.077 98.3611 121.858 99.1421C122.639 99.9232 123.905 99.9232 124.686 99.1421L137.414 86.4142ZM50 87L136 87L136 83L50 83L50 87Z"
        fill="white"
      />
    </svg>
  )
}

export default NextButtonIcon
