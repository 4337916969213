import React from 'react'

const RedCircleIcon = () => {
  return (
    <svg
      width="892"
      height="346"
      viewBox="0 0 892 346"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="236.5" cy="655" rx="655.5" ry="655" fill="#E4002B" />
    </svg>
  )
}

export default RedCircleIcon
