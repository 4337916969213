import HrizerLogoIcon from './HrizerLogoIcon'
import IconAdjust from './IconAdjust'
import IconArrow from './IconArrow'
import IconAscSorting from './IconAscSorting'
import IconAvatar from './IconAvatar'
import IconCheck from './IconCheck'
import IconChevron from './IconChevron'
import IconCircleBenefits from './IconCircleBenefits'
import IconCircleBonuses from './IconCircleBonuses'
import IconCircleCheck from './IconCircleCheck'
import IconCircleDocument from './IconCircleDocument'
import IconCircleGenders from './IconCircleGenders'
import IconCircleHome from './IconCircleHome'
import IconCircleLocation from './IconCircleLocation'
import IconCircleMinus from './IconCircleMinus'
import IconCircleRate from './IconCircleRate'
import IconCircleSalary from './IconCircleSalary'
import IconCircleTeam from './IconCircleTeam'
import IconClipboard from './IconClipboard'
import IconClose from './IconClose'
import IconComment from './IconComment'
import IconCommentFilled from './IconCommentFilled'
import IconCompany from './IconCompany'
import IconCopy from './IconCopy'
import IconDescSorting from './IconDescSorting'
import IconDisabilities from './IconDisabilities'
import IconDocument from './IconDocument'
import IconDotsAltR from './IconDotsAltR'
import IconDownload from './IconDownload'
import IconDraft from './IconDraft'
import IconEdit from './IconEdit'
import IconEditSquare from './IconEditSquare'
import IconEmail from './IconEmail'
import IconFacebook from './IconFacebook'
import IconFacebook2 from './IconFacebook2'
import IconFavorites from './IconFavorites'
import IconFilter from './IconFilter'
import IconForward from './IconForward'
import IconGoogle from './IconGoogle'
import IconHeart from './IconHeart'
import IconHeartSelectable from './IconHeartSelectable'
import IconHome from './IconHome'
import IconImage from './IconImage'
import IconInstagram from './IconInstagram'
import IconLightbulb from './IconLightbulb'
import IconLink from './IconLink'
import IconLinkedin from './IconLinkedIn'
import IconLinkedIn2 from './IconLinkedIn2'
import IconLocation from './IconLocation'
import IconLock from './IconLock'
import IconLogo from './IconLogo'
import IconMap from './IconMap'
import IconMessage from './IconMessage'
import IconMessages from './IconMessages'
import IconMicrosoft from './IconMicrosoft'
import IconPause from './IconPause'
import IconPDF from './IconPDF'
import IconPlus from './IconPlus'
import IconPrint from './IconPrint'
import IconProject from './IconProject'
import IconRefresh from './IconRefresh'
import IconRelocation from './IconRelocation'
import IconReply from './IconReply'
import IconSignOut from './IconSignOut'
import SlidersIcon from './IconSliders'
import IconSortAsc from './IconSortAsc'
import IconSortDesc from './IconSortDesc'
import IconSubscription from './IconSubscription'
import IconTiktok from './IconTiktok'
import IconTrash from './IconTrash'
import IconTwitter from './IconTwitter'
import IconUpload from './IconUpload'
import IconUser from './IconUser'
import IconWorkCase from './IconWorkCase'
import IconYoutube from './IconYoutube'
import NextButtonIcon from './NextButtonIcon'
import RedCircleIcon from './RedCircleIcon'
export {
  HrizerLogoIcon,
  IconAdjust,
  IconArrow,
  IconAscSorting,
  IconAvatar,
  IconCheck,
  IconChevron,
  IconCircleBenefits,
  IconCircleBonuses,
  IconCircleCheck,
  IconCircleDocument,
  IconCircleGenders,
  IconCircleHome,
  IconCircleLocation,
  IconCircleMinus,
  IconCircleRate,
  IconCircleSalary,
  IconCircleTeam,
  IconClipboard,
  IconClose,
  IconComment,
  IconCommentFilled,
  IconCompany,
  IconCopy,
  IconDescSorting,
  IconDisabilities,
  IconDocument,
  IconDotsAltR,
  IconDownload,
  IconDraft,
  IconEdit,
  IconEditSquare,
  IconEmail,
  IconFacebook,
  IconFacebook2,
  IconFavorites,
  IconFilter,
  IconForward,
  IconGoogle,
  IconHeart,
  IconHeartSelectable,
  IconHome,
  IconImage,
  IconInstagram,
  IconLightbulb,
  IconLink,
  IconLinkedin,
  IconLinkedIn2,
  IconLocation,
  IconLock,
  IconLogo,
  IconMap,
  IconMessage,
  IconMessages,
  IconMicrosoft,
  IconPause,
  IconPDF,
  IconPlus,
  IconPrint,
  IconProject,
  IconRefresh,
  IconRelocation,
  IconReply,
  IconSignOut,
  IconSortAsc,
  IconSortDesc,
  IconSubscription,
  IconTiktok,
  IconTrash,
  IconTwitter,
  IconUpload,
  IconUser,
  IconWorkCase,
  IconYoutube,
  NextButtonIcon,
  RedCircleIcon,
  SlidersIcon
}
